import React from 'react'
import './feature.css'

const Feature = ({ title, text }) => (
    <div className='anubis__features-container'>
        <div className="anubis__features-container__feature">
            <div className="anubis__features-container__feature-title">        
                <h1>{title}</h1>
            </div>
            <div className="anubis__features-container_feature-text">
                <p>{text}</p>
            </div>            
        </div>
        <div className='box' />
    </div>
    
  );

export default Feature