import React, { useState } from 'react'
import { RiAddLine, RiSubtractLine } from 'react-icons/ri'
import './QuestionBox.css'

const QuestionBox = ({question='',paragraph=''}) => {
  const [isOpen, setOpen] = useState(false);

  
  return (
    <div className='question__box' >
        <div className='question__title' onClick={()=>{setOpen(!isOpen)}}>
          { isOpen ?  <RiSubtractLine className='question__icon'/> : <RiAddLine className='question__icon'/>}
          <p dangerouslySetInnerHTML={{__html: question}}/>
        </div>
        <div className={ isOpen?  'question__text question__text-pd' : 'question__text hide__question'}>
            <p dangerouslySetInnerHTML={{__html: paragraph}}/>
        </div>
    </div>
  )
}

export default QuestionBox