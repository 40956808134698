import React from 'react'
import BenefitsCrypto from './containers/benefits/BenefitsCrypto'
import HeaderCrypto from './containers/header/HeaderCrypto'
import MarketsCrypto from './containers/markets/MarketsCrypto'
import ServicesSection from './containers/services/ServicesSection'
import './CryptoApp.css'
const CryptoApp = () => {
  return (
    <div >
       <HeaderCrypto/>
       <ServicesSection/>
       <BenefitsCrypto/>
       <MarketsCrypto/>       
    </div>
  )
}

export default CryptoApp