import React from 'react'
import { Titlesection, ContactForm } from '../../components'
//import  folleto_snowball2  from '../../docs/folleto_snowball2.pdf'
//import  trend_following  from '../../docs/trend_following.pdf'
// import  resumen_snowbal2  from '../../docs/resumen_snowbal2.pdf'
import  curso_introductorio  from '../../docs/curso_introductorio.pdf'
import './crypto.css'
import { useNavigate } from 'react-router-dom'

const Crypto = () => {
    
    const navigate = useNavigate();
    return (
        <div className='anubis__crypto section__margin' id="crypto_anubis">
            <Titlesection title="Crypto" subtitle="¡Nuestros CryptoServicios a un click!" /> 
            <div id="anubis_contact" className="anubis__cryptog-container">
                {/* Estrategia Spider */}
                
                <div className="anubis__crypto-container">
                    <div className="anubis__crypto-container-title">        
                        <h1>Anubis Spider</h1>
                    </div>
                    <div className="anubis__crypto-container-text">
                        <p>El cliente goza de los beneficios sin estrés o requerimiento alguno. Mientras su capital trabaja con dos operadores profesionales de forma segura y automática.<br/><br/><br/><br/><br/></p>
                        {/* <button 
                            onClick={(e) => {e.preventDefault();
                                window.open(resumen_snowbal2, '_blank');
                                //window.location.href='https://docs.google.com/document/d/1p76bZ6YllYrYiCNg0D98VXDG91fE1Rp5RSjBJqi9RTo/edit';
                            }}
                            type="button">Resumen del servicio</button> */}
                        <button 
                            onClick={(e) => {e.preventDefault();                            
                                navigate("/crypto/services?select_spider");
                                //window.location.href='https://docs.google.com/document/d/1BETxDt5F-7mmrzkzx-51nNBfoOfkfVuY522bkODrzUQ/edit';
                                }}
                            
                            type="button">Más información</button>
                    </div>            
                </div>                
                
                {/* Anubis trend following */}
                
                <div className="anubis__crypto-container">
                    <div className="anubis__crypto-container-title">        
                        <h1>Anubis trend following</h1>
                    </div>
                    <div className="anubis__crypto-container-text">
                        <p>Esta estrategia aprovecha la tendencia del mercado para realizar la compra y venta de criptomonedas, comprando en descuento y vendiendo a la alza<br/><br/><br/><br/><br/></p>                    
                        <button 
                            onClick={(e) => {e.preventDefault();                            
                                //window.open(trend_following, '_blank');
                                navigate("/crypto/services?select_trendfollowing");
                                //window.location.href='https://docs.google.com/document/d/1BETxDt5F-7mmrzkzx-51nNBfoOfkfVuY522bkODrzUQ/edit';
                                }}
                            type="button">Más información</button>
                    </div>            
                </div>                
                
                {/* Anubis JetPack */}
                
                <div className="anubis__crypto-container">
                    <div className="anubis__crypto-container-title">        
                        <h1>Anubis Jetpack</h1>
                    </div>
                    <div className="anubis__crypto-container-text">
                        <p>Incluye 3 meses de Asesoría, acceso a Anubis Academy y participación en el Anubis Trading Channel, con estas herramientas estarás listo para emprender tu propio rumbo, diseñar e implementar una estrategia personalizada y efectiva. Esta estrategia te servirá por mucho tiempo para generar retornos utilizando tu capital y tus conocimientos adquiridos<br/></p>
                        <button 
                            onClick={(e) => {e.preventDefault();
                                navigate("/crypto/services?select_jetpack");
                                //window.open(curso_introductorio, '_blank');
                                //window.location.href='https://drive.google.com/file/d/11Hw0MQyWYy1Oc5hO-JdQARvZw8B7EkGd/view';
                            }}
                            type="button">Más información</button>
                        {/* <button 
                            onClick={(e) => {e.preventDefault();
                                navigate("/crypto/services?select_trendfollowing");
                            //    window.location.href='https://drive.google.com/file/d/1fxid_zPBNrTDj-VbBw2arGENKZC6MEsu/view';
                            }}
                            type="button">Anubis Trend Following</button>
                        <button 
                            onClick={(e) => {e.preventDefault();
                                navigate("/crypto/services?select_spider");
                                //window.location.href='https://drive.google.com/file/d/1CbdklvaqRpz5PZjRZ2CkMdZJuc5PVrpf/view';
                            }}
                            type="button">Anubis Spider</button> */}
                        <button 
                            onClick={(e) => {e.preventDefault();
                                // navigate("/crypto/services?select_spider");
                                //window.location.href='https://drive.google.com/file/d/1CbdklvaqRpz5PZjRZ2CkMdZJuc5PVrpf/view';
                                window.location.href='https://info.grupoanubis.com/advisory';
                            }}
                            type="button">Asesoria gratuita</button>
                    </div>            
                </div>                
                
            </div>
            <div id='container_form' className='anubis__cryptog-container'>
                <div className="anubis__crypto-contact">
                    <div className="anubis__crypto-contact-title">        
                        <h1>Escríbenos para una consulta gratuita</h1>
                    </div>
                    <div className='box'/>
                    <div className="anubis__crypto-contact-text">
                        <p>Es un tema complejo invertir en criptomonedas. Por ello, nuestras estrategias se adaptan según el mercado, las finanzas personales y etapa de vida de cada individuo. Por eso, no dudes en contactarnos si estás interesado en invertir para cuidar y crecer tu patrimonio.</p>                                        
                    </div>            
                </div>
                <div className="anubis__crypto-contact-form" >
                    <ContactForm/>
                </div>                
            </div>
        </div>
    )
}

export default Crypto