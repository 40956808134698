import React from 'react'
import './service.css'

const Service = ({ title, text , service_img }) => {
  return (
    <div className='anubis__services-container'>
        <div className="anubis__services-container__service">
            <div className="anubis__services-container__service-image">
                <img src={service_img} alt="service_img" />                
            </div>
            <div className="anubis__services-container__service-title">        
                <h1>{title}</h1>
                <div className='box_service'/>
            </div>
            <div className="anubis__services-container_service-text">
                <p>{text}</p>
            </div>            
        </div>
        
    </div>
  )
}

export default Service