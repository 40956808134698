import React from 'react'

import QuestionBox from '../../../components/QuestionBox/QuestionBox'
import './Questions.css'

const Questions = () => {
  return (
    <div className='crypto__questions section__padding'>
        <div className='crypto__questions-title'>
            <h1>Preguntas frecuentes</h1>
        </div>

        <div className='crypto__questions-container'>
            <div className='crypto__questions-container-left'>
                <QuestionBox question='¿Es posible perder toda la inversión?'
                    paragraph='Toda inversión tiene su riesgo, el porcentaje de personas que han invertido con nosotros y que han perdido toda su inversión es 0%. Sin embargo, es posible perder toda una inversión. Nosotros ofrecemos asesorías y estrategias que aumentan las probabilidades de generar retornos considerables pero no son garantizados.'/>

                <QuestionBox question='¿Cómo realizo la compra de criptomonedas?'
                    paragraph='A través de un broker o un DEX es fácil comprar criptomonedas. Tenemos un video tutorial de como fondear una cuenta en un broker/billetera. En el caso de que se trate de una estrategia de inversión ejecutada por Anubis, nosotros nos encargamos de las operaciones. Nosotros administramos y monitoreamos las cuentas en todo momento.'/>

                <QuestionBox question='¿Cuál es el plazo de la inversión en las estrategias de Anubis?' 
                    paragraph='Mínimo 12 meses para poder considerar suficientemente bien ejecutadas nuestras estrategias de inversión.'/>

                <QuestionBox question='¿Cuál será el retorno aproximado de mi inversión?'
                    paragraph='Puedes calcular el retorno aproximado del servicio escogido usando la calculadora que está en nuestra página web <a href="https://grupoanubis.com/crypto/services#calculator_snider"> Calculadora </a> ¡Resultados históricos no garantizan resultados futuros!'/>
                
                <QuestionBox question='¿Puedo utilizar la cuenta del broker para comprar monedas por mi cuenta?'
                    paragraph='Si es posible. El cliente debe informar que realizará compra de criptomonedas por su cuenta y la inversión estará separada de las estrategias de Anubis. Tratamos de mantener un modelo flexible y amigable para lograr acomodar a cualquier cliente. Generalmente los clientes que llevan sus inversiones aparte de Anubis Crypto acostumbran a hacerlo en otra cuenta para mantener separadas las aventuras de inversión sin necesidad de que nosotros estemos al tanto.'/>
                {/* Preguntas simples */}
                <QuestionBox question='¿Cuándo tengo que pagar comisiones?'
                    paragraph='Se cobra una comisión inicial al empezar la estrategia. Adicionalmente, nosotros cobramos un fee mensual y realizamos un corte cada 3 meses (Spider) y cada 6 meses (Trend Following) en donde entregamos un reporte con el rendimiento de la inversión y las comisiones a pagar por desempeño.'/>
                
                <QuestionBox question='¿Cómo puedo agendar una reunión?'
                    paragraph='Nos puedes contactar a través de cualquiera de nuestros canales de comunicación: página web, redes sociales o vía correo electrónico. Nos pondremos en contacto contigo a la brevedad.'/>
                
                <QuestionBox question='¿Cual es el proceso para empezar a invertir con Anubis?'
                    paragraph='Para comenzar necesitamos conocer un poco más de ti, para esto es necesario que llenes un formulario aplicativo con información personal para que nuestro equipo evalúe el perfil de inversión. 
                    <br/>
                    <br/>
                    Una vez aceptada la aplicación, programamos una reunión para presentar nuestras estrategias y aclarar cualquier duda. Finalmente, llegado a un acuerdo se firma el contrato, se crea la cuenta del broker a tu nombre, se pagan comisiones y se hace el funding para ¡arrancar con la inversión!
                    '/>
                
                <QuestionBox question='¿Qué necesita Anubis de mi parte para empezar la estrategia?'
                    paragraph='Es necesario llenar la aplicación para ser cliente. Una vez aceptada dicha aplicación, firmar el contrato, realizar verificación de identidad en su cuenta del broker, pagar la comisión inicial, y fondear su nueva cuenta de inversión.'/>

                {/* <QuestionBox question=''
                    paragraph=''/> */}
                
            </div>
            <div className='crypto__questions-container-right'>
                <QuestionBox question='¿Puedo sacar mi dinero de las estrategias en cualquier momento?'
                    paragraph='Si, en cualquier momento se puede retirar el dinero de la inversión por el valor presente del mercado. La estrategia será detenida cuando el cliente nos lo ordene (De acuerdo con el contrato), sin embargo enfatizamos en que a cada estrategia se le debe dar un mínimo de 12 meses para considerarse bien ejecutada.'/>

                <QuestionBox question='¿Se puede pagar el costo del servicio con las utilidades de la inversión?' 
                    paragraph='Si, muchos de nuestros clientes cubren el costo del servicio con las utilidades generadas por las estrategias.'/>
                
                <QuestionBox question='¿Cómo transfiero el capital a invertir a mi cuenta que voy a usar para las estrategias?'
                    paragraph='El cliente transfiere el monto de la inversión directamente a su cuenta del broker creada por Anubis usando uno de los diferentes métodos recomendados. Nosotros no captamos dinero, solamente el costo del servicio y comisiones por desempeño se pagan a Anubis S.A. 
                        <br/><br/> Nota: <strong> Nosotros no implementamos nuestras estrategias con fondos que provengan de P2P de Binance. </strong> ' />

                <QuestionBox question='¿Puedo transferir el monto a invertir desde la cuenta bancaria de mi empresa o de un tercero?'
                    paragraph='Si, las transferencias tienen que realizarse directamente desde una cuenta bancaria que tenga el mismo nombre que la cuenta del broker; sea esta cuenta personal o de empresa.'/>

                {/* Preguntas simples */}
                <QuestionBox question='¿Puedo ver el rendimiento de mi inversión?'
                    paragraph='Si, la inversión se puede ver en todo momento ingresando con las credenciales del cliente a la plataforma de Anubis o el Broker, ya sea en el celular o PC.'/>
                
                <QuestionBox question='¿Necesito tener conocimientos básicos en Criptomonedas para invertir con ustedes?'
                    paragraph='No es necesario. Anubis cuenta con analistas que tienen años de experiencia y conocimiento de los mercados de criptomonedas. Nosotros nos encargamos de asesorar y ejecutar estrategias para nuestros clientes.'/>
                
                <QuestionBox question='¿Puedo utilizar la cuenta del broker para comprar monedas por mi cuenta?'
                    paragraph='Si es posible. El cliente debe informar que realizará compra de criptomonedas por su cuenta y la inversión estará separada de las estrategias de Anubis para evitar confusiones.'/>
                
                <QuestionBox question='¿Por qué invertir con Anubis?'
                    paragraph='Anubis cuenta con un equipo de profesionales apasionados por la tecnología y nuevas tendencias. Contamos con 5+ años de experiencia en el mercado de criptomonedas estudiando y aprendiendo sobre las nuevas tecnologías emergentes. Nuestras estrategias nos han generado grandes retornos en el pasado. 
                    Queremos ser líderes en el mercado ofreciendo estrategias y administrando portafolios para inversionistas. Nos caracterizamos por nuestros valores de profesionalismo, trabajo en equipo, honestidad, y transparencia. 
                    '/>
                
                <QuestionBox question='¿Cuándo tengo que pagar comisiones?'
                    paragraph='Se cobra una comisión inicial al empezar la estrategia. Adicionalmente, nosotros realizamos un corte cada 3 meses (Spider) y cada 6 meses (Trend Following) en donde entregamos un reporte con el rendimiento de la inversión y las comisiones a pagar por desempeño.'/>

                {/* <QuestionBox question=''
                    paragraph=''/> */}
            </div>
        </div>
    </div>
  )
}

export default Questions