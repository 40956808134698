import React from 'react'
// import PropTypes  from 'prop-types'
import row_down_red from '../../../assets/row_down_red.png'
import row_up_green from '../../../assets/row_up_green.png'
import triangle_right from '../../../assets/triangle_right.svg'

import './SingleMarket.css'
const SingleMarket = ({
    name,
    abr,
    icon,
    value,
    percentage
}) => {    
    return (
    <div className='crypto__singlemarket' >
        <div className='crypto__singlemarket_info'>
            <div className='crypto__singlemarket_info-row_right'>
                <img src={triangle_right} alt='triangle_right'/> 
            </div>
            <div className='crypto__singlemarket_info-text'>
                <p className='name'>{name}</p>
                <p className='abr'>{abr}</p>  
            </div>
            <div className='crypto__singlemarket_info-icon'>
                <img src={icon} alt='crypto_icon'/> 
            </div>
        </div>
        <div className='crypto__singlemarket_value'>
            <p>$ {value}</p>
        </div>        
        { 
            percentage>0 ?
            <div className='crypto__singlemarket_percentage'>
                <img src={row_up_green} alt='row_up_green'/> 
                <p className='crypto__singlemarket_percentage_positive'>{percentage} %</p>
            </div>
            :
            <div className='crypto__singlemarket_percentage'>
                <img src={row_down_red} alt='row_down_red'/>
                <p className='crypto__singlemarket_percentage_negative'>{percentage} %</p>
            </div>
        }            
        
    </div>
    )
}


// SingleMarket.propTypes= {
//     name: PropTypes.string,
//     abr: PropTypes.string,
//     icon: PropTypes.string,
//     value: PropTypes.string,
//     percentage: PropTypes.number,
// }
export default SingleMarket