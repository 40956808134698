export const getMarkets = async () => {
    const aws_bucket = "https://anubis-cryptobucket.s3.us-west-2.amazonaws.com/"
    const type_img = ".png";
    const url = "https://data.messari.io/api/v2/assets?limit=10";
    const resp = await fetch(url);
    const {data} = await resp.json();         
    const markets = data.map(crypto => {        
        return{
            id: crypto.id,
            name : crypto.name,
            abr: crypto.symbol,
            icon: aws_bucket+crypto.symbol+type_img,
            value: crypto.metrics.market_data.price_usd,
            percentage: crypto.metrics.market_data.percent_change_usd_last_1_hour
        }
    });
     
     return markets;   
    
};