import React from 'react'
import {  Navbar } from './components'
import { Analysis,Footer,Header,Services,Soon,WhatANUBIS,Crypto} from './containers'

import './PrincipalApp.css'
const PrincipalApp = () => {
  document.title = "Grupo Anubis";
  return (
    <div className='PrincipalApp'>
        <div >
            <Navbar/>
            <Header/>
        </div>
        <div className='gradient__bg'>
            <WhatANUBIS/>
            {/* <Brand/>             */}
            {/* <Features/> */}
            <Services/>            
            <Crypto/>
            <Analysis/>
            {/* <Testimonials/> */}
            {/* <CTA/>
            <Blog/> */}
            <Soon/>
            <Footer/>
        </div>
        
    </div>
  )
}

export default PrincipalApp