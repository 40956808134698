import React from 'react'
import { useFormik } from "formik";
import saveUserData from '../../helpers/saveUserData';
import './datauserform.css';
export const DataUserForm = () => {
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
        },
        onSubmit: (values) => {
            let { name, email } = values;
            
            name = name.toUpperCase();
            email = email.toLowerCase();
            
            saveUserData(name, email).then((response) => {
                console.log(response)
                if (response.ok) {
                  return response;
                }
                throw new Error('Something went wrong');
            }).catch(error => {
                console.error('There was an error!', error);
            });
            
        }})
  return (
    <div>
        <form onSubmit={formik.handleSubmit} className="form-anubis">
                <div>                
                    

                    <input
                        type="text"
                        name="name"
                        placeholder="Nombres y Apellidos"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        className="input-anubis"
                        
                    />
                   
                    <input
                        type="email"
                        name="email"
                        placeholder="Correo"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        className="input-anubis"
                    />
                </div>
                <button type="submit" className="button-anubis" onClick={() => {
                    document.getElementById("popup").style.visibility='hidden';
                    window.location.href='https://info.grupoanubis.com/advisory'
                    }}>
                    Registrar
                </button>
            </form>
    </div>
  )
}
