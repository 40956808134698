import { useEffect, useState } from "react";
import { getMarkets } from "../helpers/getMarkets";
import { useInterval } from "./useInterval";
export const useFetchMarkets = () => {
    const [state, setstate] = useState({
        data:[]
    });

    const obtainMarket = () => {           
        getMarkets()
        .then( cryptos =>{                
            setstate({
                data:cryptos                    
            })                                    
        });
    };
            
    useEffect(()=>{
        obtainMarket();
    }, []);

    useInterval(
        ()=>{
            obtainMarket();
        },
        5000
    );    
    return state;
};

