import React from 'react'
import { Titlesection, Service } from '../../components'
import monitoreo from '../../../assets/monitoreo.svg'
import crypto from '../../../assets/crypto.svg'
import './services.css'
const Services = () => {
    return (
      <div className="anubis__services section__margin" id="services_anubis">          
          <Titlesection title="Servicios" /> 
          <div className="anubis__servicesg-container">
            <Service service_img={monitoreo} title="Watch" text="Se enfoca en la utilización de herramientas open source para la implementación de un sistema de Nodos y Gateways para lectura y envío de datos, según los parámetros que se requieran enviar, ya sean pH, salinidad, oxígeno, corriente, vibraciones y demás. " />
            <Service service_img={crypto} title="Crypto" text="Consiste en brindar asesorías, cursos y estrategias de inversión a personas que quieran diversificar su portafolio en Criptomonedas. Facilitamos al cliente el uso de herramientas en nuestra web para que puedan visualizar en tiempo real sus inversiones. Contamos con expertos con años de experiencia en estas nuevas tecnologias de finanzas descentralizadas." />            
          </div>         
      </div>
    )
}

export default Services