import React, { useState } from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";
import PrincipalApp from '../principal/PrincipalApp';
import CryptoRouter from './CryptoRouter';
import ScrollToTop from './ScrollToTop';

const AppRouter = () => {
  const path = window.location.href;
  // console.log(path);
  const [route,setRoute] = useState(path);
  return (
    <BrowserRouter>   
        <ScrollToTop setRoute={setRoute}/>             
        <Routes>
            <Route path="/" element={ <PrincipalApp />} />
            <Route path="/crypto/*" element={ <CryptoRouter route={route} />} />
        </Routes>
    </BrowserRouter>
  )
}

export default AppRouter