import React from 'react'
import { Titlesection } from '../../../principal/components'
import credit_card from '../../../assets/credit_card.png'
import './BenefitsCrypto.css'

const BenefitsCrypto = () => {
  return (
    <div className='crypto__benefits section__margin_left'>
        <Titlesection title="Beneficios"  />
        <div className='crypto__benefits-container'>
            <div className='crypto__benefits-container_img'>
              <img src={credit_card} alt="credit_cardimg" />   
            </div>
            <div className='crypto__benefits-container_text'>
              <p>
                Paga todos nuestros servicios con tarjeta de crédito o débito.
              </p>
            </div>
            <div className='crypto__benefits-container_img-mobile'>
              <img src={credit_card} alt="credit_cardimg" />   
            </div>            
        </div>        
    </div>
  )
}

export default BenefitsCrypto