import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom";

import '../../../principal/components/navbar/navbar.css'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import logo from '../../../assets/logo_anubis.svg'

const NavbarCrypto = ({route}) => {
    document.title = "Grupo Anubis | Crypto";
    const [toggleMenu, setToggleMenu] = useState(false);
    const [principalRoute, setPrincipalRoute] = useState(false);    
    const { pathname } = useLocation();
    useEffect(()=>{
        setTimeout(()=>{
            if(toggleMenu){
                setToggleMenu(false);
            }
        },5000);
    },[ toggleMenu ]);

    useEffect(() => {        
        // console.log(route);
        // console.log(pathname);
        if(!(pathname.includes("crypto/services") || pathname.includes("crypto/help"))){            
            setPrincipalRoute(true);
            window.onscroll = function() {
                navbarScroll();
            };
            let navbar = document.getElementById("navbar");
            let services_crypto = document.getElementById("services_crypto");
            let home = document.getElementById("home");

            let sticky = services_crypto.offsetTop;
            let hide_show = home.offsetTop;
            // console.log(sticky);
            // console.log(hide_show);

            let before= 0;
            let down = true;
            const navbarScroll = () =>{
                let now = window.pageYOffset;         
                down = now>before? true: false;                                    
                if (now  < sticky && now  > hide_show ) {
                    if(down){
                        navbar.classList.add("hide_navbar");
                    }else{
                        navbar.classList.remove("hide_navbar");
                    }
                } 
                else if(window.pageYOffset > sticky){
                    navbar.classList.add("sticky_navbar");
                }
                else {                
                    navbar.classList.remove("sticky_navbar");
                }

                before=now;
            }
        }else{            
            setPrincipalRoute(false);
        }                
        
    },[pathname]);



    return (
        <div className='anubis__navbar' id="navbar">
            <div className='anubis__navbar-links'>
                <div className="anubis__navbar-links_logo">
                    <img src={logo} alt="logo"></img>
                    <div>                        
                        { principalRoute ? 
                                <p><a href="#home">ANUBIS <span> crypto</span></a></p> : 
                                <p><NavLink className="nav_link" 
                                    to="/crypto" 
                                    onClick={()=>{
                                        setPrincipalRoute(true);
                                    }}>ANUBIS <span> crypto</span></NavLink></p> } 
                    </div>
                    
                </div>
                <div className='anubis__navbar-links_container'>                                        
                    <p><NavLink className="nav_link" to="/">Anubis</NavLink></p>
                                       
                    {/* <p><a href='#services_anubis'>Crypto</a></p> */}
                    <p><NavLink className="nav_link" 
                                to="/crypto/services" 
                                onClick={()=>{
                                    setPrincipalRoute(false);
                                }}>Servicios</NavLink></p>
                    <p><NavLink className="nav_link" 
                                to="/crypto/help" 
                                onClick={()=>{
                                    setPrincipalRoute(false);
                                }}>Ayuda</NavLink></p>   
                    {/* <p><a href='#anubis_contact'>Blog</a></p> */}
                                                    
                </div>
            </div>

            <div className="anubis__navbar-sign">                
                <button className='buttonNavbar' 
                    onClick={(e) => {e.preventDefault();
                        window.location.href="http://crypto.grupoanubis.com"                            
                        // ReactGA.event({
                        //     category: 'LINKS_SERVICES',
                        //     action: 'MOVE_TO_SECTION',
                        //     label: 'MONITOREO'
                        // });
                    }}
                type="button">Ingresar</button>
            </div>

            <div className="anubis__navbar-menu">
                {toggleMenu
                    ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
                {toggleMenu && (
                <div className="anubis__navbar-menu_container scale-up-center">
                <div className="anubis__navbar-menu_container-links">                    
                    <p><NavLink className="nav_link" to="/">Anubis</NavLink></p>
                    {/* <p><a href='#services_anubis'>Crypto</a></p> */}
                    {/* <p><a href='#services_anubis'>Crypto</a></p> */}
                    <p><NavLink className="nav_link" 
                                to="/crypto/services" 
                                onClick={()=>{
                                    setPrincipalRoute(false);
                                }}>Servicios</NavLink></p> 
                    {/* <p><a href='#anubis_contact'>Blog</a></p> */}
                    <p><NavLink className="nav_link" 
                                to="/crypto/help" 
                                onClick={()=>{
                                    setPrincipalRoute(false);
                                }}>Ayuda</NavLink></p>  
                </div>
                <div className="anubis__navbar-menu_container-links-sign">
                    <button type="button" onClick={(e) => {e.preventDefault();
                            window.location.href="http://crypto.grupoanubis.com"                            
                            // ReactGA.event({
                            //     category: 'LINKS_SERVICES',
                            //     action: 'MOVE_TO_SECTION',
                            //     label: 'MONITOREO'
                            // });
                        }}>Ingresar</button>
                </div>
            </div>
                )}
            </div>
        </div>
    )
}

export default NavbarCrypto