import React from 'react';
import './CalculatorValue.css';

const CalculatorValue = ({title,value,isPercentage,returnPercentage,enumerator,isFinalCapital=false}) => {
  return (
    <div className={isFinalCapital ? 'crypto__calculator-final': 'crypto__calculator'}>
      <p>{title} <sup>{enumerator}</sup> </p>  
          {/* value symbol */}
          <div className='crypto__calculator-container'>
            <span> {isPercentage? value + '%': '$'  + value }</span>
            { returnPercentage ? (returnPercentage > 0 ? <span className='crypto__calculator-container-percentage green_value'> { '+ ' + returnPercentage + '%'} </span> 
              :  <span className='crypto__calculator-container-percentage red_value'> {  returnPercentage + '%'} </span>) : ''}
          </div>
    </div>
  )
}

export default CalculatorValue