import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';


import './index.css';
import AppRouter from './routers/AppRouter';


ReactGA.initialize('UA-221752500-1');
ReactGA.pageview(window.location.pathname);
ReactDOM.render(
    <AppRouter/>,
    document.getElementById('root')
);