import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Titlesection } from '../../../principal/components'

import './MarketCrypto.css'
import SingleMarket from '../../components/market/SingleMarket';
import { useFetchMarkets } from '../../hooks/useFetchMarkets';

// import CustomRightArrow from '../../components/ui/CustomRightArrow';

const MarketsCrypto = (props) => {

  
  const {data:markets}= useFetchMarkets();  
    

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1 
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  };

  return (
    <div className='crypto__markets section__margin'>
        <Titlesection title="Mercado"  /> 
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            // customRightArrow={<CustomRightArrow />}            
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-margin-40-px"
            
          >

            {markets.map(crypto => {
                  
                    return <SingleMarket  key={crypto.id} 
                      name= {crypto.name}
                      abr={crypto.abr}
                      icon={crypto.icon.toLowerCase()}
                      value= {crypto.value?.toFixed(2).toString()}
                      percentage= {crypto.percentage ? crypto.percentage.toFixed(2) : (0).toFixed(2)}
                    />} 
                )}
            {/* <SingleMarket 
              name='Bitcoin'
              abr='BTC'
              icon={bitcoin}
              value= '$43,435.00'
              percentage= {2.9}
            />

            <SingleMarket 
              name='Sandbox'
              abr='SAND'
              icon={sandbox}              
              value= '$3.43'
              percentage= {-1.3}
            />


            <SingleMarket 
              name='Solana'
              abr='SOL'
              icon={solana}
              value= '$104.34'
              percentage= {6.3}
            />

            <SingleMarket 
              name='Terra'
              abr='LUNA'
              icon={terra}
              value= '$93.12'
              percentage= {-1.3}
            /> */}
                        
          </Carousel>
                
        
    </div>
  )
}

export default MarketsCrypto