import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({setRoute}) {
  const { pathname } = useLocation();

  useEffect(() => {
    if(!window.location.href.includes('help#help_contact')){
      window.scrollTo(0, 0);    
    }else{
      window.location.hash='help_contact';
    }
    setRoute(window.location.href);
  }, [pathname]);

  return null;
}