import React from 'react'
import { useNavigate } from 'react-router-dom'

import triangle_right from '../../../assets/triangle_right.svg'

import './ServiceCrypto.css'
const ServiceCrypto = ({ navigation, title, text , service_img }) => {
    const navigate = useNavigate();
    return (
        <div className='crypto__service-container'>        
            <div className="crypto__service-container__service-image">
                <img src={service_img} alt="service_img" />                
            </div>
            <div className='crypto__service-container__service-info'>
                <div className="crypto__service-container__service-title">        
                    <h1>{title}</h1>                
                </div>
                <div className="crypto__service-container_service-text">
                    <p>{text}</p>
                </div>
                <div className='crypto__service-container_service-button'>
                    <button className='learn-more__button' 
                                
                                onClick={(e) => {e.preventDefault();
                                    navigate("/crypto/services?"+navigation);
                                    // ReactGA.event({
                                    //     category: 'LINKS_SERVICES',
                                    //     action: 'MOVE_TO_SECTION',
                                    //     label: 'CRYPTO'
                                    // });
                                }}
                                type="button">
                        <div className='learn-more__button-inside'>
                            <p>CONOCER MÁS</p>
                            <img src={triangle_right} alt="service_img" /> 
                        </div>
                        
                    </button>
                </div>
            </div>                
        </div>
    )
}

export default ServiceCrypto