import React from 'react'
import { Titlesection, Feature } from '../../components';
import { wanubis_image, graph_crypto, spider_crypto, gateway_analysis, node_analysis } from './import'
import './whatANUBIS.css'
const WhatANUBIS = () => {
  return (
    <div className="anubis__whatanubis section__margin" id="wanubis">
        
        <Titlesection title="Anubis" subtitle="¿Quienes somos y cómo podemos ayudarte?" />
        <div className="anubis__whatanubis-container">
          <div className='anubis__whatanubis-container-design'>
            <div className='anubis__whatanubis-container-image'>
              <img alt="wanubis_image" src={wanubis_image}/>
            </div>
            <div className='anubis__whatanubis-graphs'>
              <div className='anubis__whatanubis-graphs-crypto'>
                  <p>CRYPTO</p>
                  <div className='anubis__whatanubis-graphs-cryptoimages'>
                    <div className='anubis__whatanubis-graphs-cryptotrading'>
                      <img alt="graph_crypto" src={graph_crypto}/>
                      <p>Anubis Trend Following</p>
                    </div>
                    <div className='anubis__whatanubis-graphs-cryptosnow'>
                      <img alt="spider_crypto" src={spider_crypto}/>
                      <p>Spider strategy</p>
                    </div>
                  </div>
                  
              </div>
              <div className='anubis__whatanubis-graphs-analysis'>
                  <p>ANUBIS WATCH</p>
                  <div className='anubis__whatanubis-graphs-analysisimages'>
                    <div className='anubis__whatanubis-graphs-analysisgw'>
                      <img alt="gateway_analysis" src={gateway_analysis}/>
                    </div>
                    <div className='anubis__whatanubis-graphs-analysisnode'>
                      <img alt="node_analysis" src={node_analysis}/>
                    </div>
                  </div>
                  <p className='anubis__whatanubis-graphs-analysis-subtitle'>Sistema de  Nodos y Gateways</p>
                  
              </div>
            </div>
          </div>          
        </div>
        <div className="anubis__whatanubis-container">
            <Feature title="¿Quienes somos?" text="Somos un grupo de profesionales con similares principios y ética. Nos juntamos a través de la tecnología para servir de la mejor forma a nuestros clientes. No nos asusta contemplar ideas creativas, jóvenes o implementar nuevas tecnologías. Estamos activamente mejorando la vida y aumentando la productividad de nuestros clientes." />
            <Feature title="Misión" text="Hacer más eficiente la vida de individuos o empresas a través de tecnología innovadora, ofreciendo a nuestros clientes soluciones efectivas que disminuyan sus preocupaciones." />
            <Feature title="Visión" text="Brindar a nivel regional servicios de calidad relacionados con software e innovación. Queremos llegar a mejorar la calidad de vida y hacer más eficiente el trabajo de muchos sin discriminar. " />
        </div>

        {/* <Contactinfo/> */}
    </div>
  )
}

export default WhatANUBIS