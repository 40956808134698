import React, { useEffect } from 'react';
import './ListServices.css'
import InfoBox from '../../../components/infobox/InfoBox';
import logo from '../../../../assets/images/logo_anubis.svg';

// import video_test from '../../../../assets/video_test.png';
import spider_icon from '../../../../assets/spider_icon.svg';
import tendency from '../../../../assets/tendency.svg';
import cake_spider from '../../../../assets/cake_spider.svg';

import bit2usd from '../../../../assets/bit2usd.svg';
import graph_trend from '../../../../assets/graph_trend.svg';
import cake_trend from '../../../../assets/cake_trend.svg';

import user_jetpack from '../../../../assets/user_jetpack.svg';
import crypto_jetpack from '../../../../assets/crypto_jetpack.svg';
import graph_jetpack from '../../../../assets/cake_trend.svg';

import CalculatorUI from '../../../components/calculator/CalculatorUI';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import YoutubeEmbed from '../../../components/embed/Embed';
import { InlineWidget } from 'react-calendly';
import { DataUserForm } from '../../../../crypto/components/userdata/DataUserForm';


const ListServices = ({route}) => {
    const animateIn = 'animate__animated animate__fadeIn';    
    const isMobile = useCheckMobileScreen();    

    useEffect(()=>{        
        const nav_opt = route.split('?');
        
        if(nav_opt[1]){
            // console.log(nav_opt);
            navigateOption(nav_opt[1]);            
        }else{
            navigateOption('select_spider');
        }
    },[route]);

    const navigateOption = (id)=>{
        const arrayServices = document.querySelectorAll('.crypto__listservices-list a');    
        // console.log(arrayServices);
        let findId= false;    
        arrayServices.forEach(service=>{            
            const service_box =  document.getElementById(service.getAttribute("data-value"));            
            if(service.id === id){                
                service.classList.add("select");
                service_box.classList.remove("hide");                
                findId=true;
            }else{
                service.classList.remove("select");
                service_box.classList.add("hide");
            }
        });

        if(!findId){
            arrayServices.forEach(service=>{            
                const service_box =  document.getElementById(service.getAttribute("data-value"));            
                if(service.id === "select_spider"){                
                    service.classList.add("select");
                    service_box.classList.remove("hide");
                    findId=true;
                }else{
                    service.classList.remove("select");
                    service_box.classList.add("hide");
                }
            });
        }
    }

    const selectOption = (e) =>{        
        e.preventDefault();        
        window.history.replaceState(null, null, "?" + e.target.id);
        navigateOption(e.target.id);
    }

         
        
    return (
        <div className='crypto__listservices section__padding'>
            <div className='crypto__listservices-title'>
                <h1>SERVICIOS</h1>
            </div>
            <div className='crypto__listservices-list'>                                    
                {/* <div>
                    <a href='?select_spider' id="select_spider" data-value="spider" className='select'  onClick={selectOption}>Spider</a>
                </div> */}
                <div>
                    <a href='?select_trendfollowing' id="select_trendfollowing" data-value="trend_following" onClick={selectOption} >{isMobile ? 'Trend' : 'Trend Following'}</a>
                </div>
                <div>
                    <a href='?select_jetpack' id="select_jetpack" data-value="jetpack" onClick={selectOption} >Jetpack</a>
                </div>
            </div>

            {/* Spider */}
            {/* <div id="spider" className={`crypto__listservices-info ${animateIn}`} >
                <div className='crypto__listservices-info-title'>
                    <h2>Spider</h2>
                </div>

                <div className='crypto__listservices-info-video  crypto__listservices-info__padding crypto__listservices-info__margin'>                    
                    <YoutubeEmbed embedId='iHFnrGy_FoM' titleEmbed='Spider'/>                    
                </div>

                <div className='crypto__listservices-info-boxes  crypto__listservices-info__padding crypto__listservices-info__margin'>
                    <InfoBox img_box={spider_icon} text_box="Es una estrategia de trading activo que busca generar retornos de la volatilidad de los mercados de criptomonedas. Está compuesta por 2 operadores humanos que manejan el portafolio. Estos operadores con mucha experiencia hacen trading en spot o en futuros, pueden aprovechar de movimientos alcistas o bajistas para realizar una ganancia."/>
                    <InfoBox img_box={tendency} text_box="Como meta, buscamos obtener un 60% de retorno anual, luego de los costos operativos. Para analizar si te interesa, revisa nuestra calculadora de proyección de la estrategia, ingresa el capital que deseas invertir y el plazo. Con esto podrás ver el retorno aproximado, los costos operativos y las comisiones totales." side_left={!isMobile? false: true}/>
                    <InfoBox img_box={cake_spider} text_box="Anubis Spider es ideal para diversificar un portafolio debido a su baja correlación con el mercado. Adicionalmente, puede ser implementada en cualquier momento, sin importar la tendencia del mercado. La estrategia está automatizada, Utilizamos nuestro software propietario para replicar las operaciones en tiempo real desde nuestra cuenta master."/>
                </div>

                <div className='crypto__listservices-info-calculator'>
                    <div className='crypto__listservices-info-calculator-title'>
                        <h3>Realiza una proyección de tu inversión</h3>
                    </div>
                    <div className='crypto__listservices-info-calculator-text'>
                        <p>Con la calculadora de inversión de Anubis podrás tener una noción del retorno que pudieras conseguir en la estrategia SPIDER, los cálculos se realizan en base a datos históricos y no garantizan resultados futuros</p>
                    </div>
                    <div className='crypto__listservices-info-calculator-link'>
                        <a href='#calculator_spider'>
                            Ir a calculadora
                        </a>
                    </div>
                </div>

                <CalculatorUI id={'calculator_spider'} name_strategy={'Spider'}/> 

                <div className='crypto__listservices-calculator-info'>
                    <p><sup>1</sup> 12 meses el plazo mínimo recomendado por Anubis Crypto.</p>
                    <p><sup>2</sup> 12% sobre la utilidad restando el costo operativo total</p>
                    <p><sup>3</sup> Este es un aproximado con data del pasado y no es asegurado.<br></br>   Resultados históricos no garantizan resultados futuros.</p>
                </div>                            

                <div className='crypto__listservices-link'>
                    <h1>¿Interesado en invertir?</h1>
                    <div className='crypto__listservices-link-container'>
                        <div className='crypto__listservices-link-button'>                                                
                            <a href='#calendly'  onClick={()=>{
                                    document.getElementById("calendly").style.display='block';                                    
                                }}                  
                            >Contratar</a>
                        </div>
                        <div className='crypto__listservices-link-button'>                                                
                            <a href='https://info.grupoanubis.com/advisory'>Asesoría gratis</a>
                        </div>
                        
                        <div className='crypto__listservices-link-button'>                                                
                            <a 
                                href='https://anubiscrypto.substack.com/'                 
                            >Newsletter</a>
                        </div>

                        <div className='crypto__listservices-link-button'>                                                
                            <a 
                                href='https://info.grupoanubis.com/'                 
                            >Suscríbete</a>
                        </div>
                    </div>
                </div>   
            </div> */}

            

            {/* Anubis Trend Following */}
            <div id='trend_following' className={`crypto__listservices-info ${animateIn} hide`}>
                <div className='crypto__listservices-info-title'>
                    <h2>Trend Following</h2>
                </div>

                <div className='crypto__listservices-info-video  crypto__listservices-info__padding crypto__listservices-info__margin'>
                    {/* <img src={video_test} alt="test_video" ></img> */}
                    <YoutubeEmbed embedId='jHpB1QcNkto' titleEmbed='Trend Following'/>
                </div>

                <div className='crypto__listservices-info-boxes  crypto__listservices-info__padding crypto__listservices-info__margin'>
                    <InfoBox img_box={bit2usd} text_box="Estrategia que sigue la tendencia del mercado produciendo ganancias. Basada en elegir, comprar y vender criptomonedas; genera utilidades al comprar bajo y vender alto."/>
                    <InfoBox img_box={graph_trend} text_box="Utiliza información leída de indicadores técnicos, on-chain data y herramientas de predicción con inteligencia artificial llegando a diversificar el portafolio con diferentes tamaños de capitalización de mercado, potencial de crecimiento, y proyectos sólidos." side_left={!isMobile? false: true}/>
                    <InfoBox img_box={cake_trend} text_box="Realizamos cortes trimestrales donde se evalúa el rendimiento de la inversión, se hacen pagos de dividendos y utilidades. ¡Te invitamos a ser parte de esta estrategia premium! Encuéntrala a partir de $20/mensuales"/>

                </div>

                <div className='crypto__listservices-info-calculator'>
                    <div className='crypto__listservices-info-calculator-title'>
                        <h3>Realiza una proyección de tu inversión</h3>
                    </div>
                    <div className='crypto__listservices-info-calculator-text'>
                        <p>Con la calculadora de inversión de Anubis podrás tener una noción del retorno que pudieras conseguir en la estrategia TREND FOLLOWING, los cálculos se realizan en base a datos históricos y no garantizan resultados futuros</p>
                    </div>                
                    <div className='crypto__listservices-info-calculator-link'>
                        <a href='#calculator_trend_following'>
                            Ir a calculadora
                        </a>
                    </div>
                </div>

                <CalculatorUI id={'calculator_trend_following'} name_strategy={'Trend Following'}/> 

                <div className='crypto__listservices-calculator-info'>
                    <p><sup>1</sup> 12 meses el plazo mínimo recomendado por Anubis Crypto.</p>
                    {/* <p><sup>2</sup> 12% sobre la utilidad restando el costo operativo total</p> */}
                    <p><sup>2</sup> Este es un aproximado con data del pasado y no es asegurado.<br></br>   Resultados históricos no garantizan resultados futuros.</p>
                </div>          


                <div className='crypto__listservices-link'>
                    <h1>¿Interesado en invertir?</h1>
                    <div className='crypto__listservices-link-container'>
                        <div className='crypto__listservices-link-button'>                                                
                            <a href='#calendly'  onClick={()=>{                                    
                                    document.getElementById("calendly").style.display='block';                                    
                                }}                  
                            >Contratar</a>
                        </div>
                        <div className='crypto__listservices-link-button'>                                                
                            <a href='https://info.grupoanubis.com/advisory'>Asesoría gratis</a>
                        </div>
                        
                        <div className='crypto__listservices-link-button'>                                                
                            <a href='https://anubiscrypto.substack.com/'                 
                            >Newsletter</a>
                        </div>

                        <div className='crypto__listservices-link-button'>                                                
                            <a 
                                href='https://info.grupoanubis.com/'                 
                            >Suscríbete</a>
                        </div>
                    </div>
                </div>               
            </div>
            

            {/* JetPack */}
            <div id='jetpack' className={`crypto__listservices-info ${animateIn} hide`}>
                <div className='crypto__listservices-info-title'>
                    <h2>JetPack</h2>
                </div>

                <div className='crypto__listservices-info-video  crypto__listservices-info__padding crypto__listservices-info__margin'>                    
                    <YoutubeEmbed embedId='Ym-XpCWyZDU' titleEmbed='Spider'/>
                    {/* <img src={video_test} alt="test_video" ></img> */}
                </div>

                <div className='crypto__listservices-info-boxes  crypto__listservices-info__padding crypto__listservices-info__margin'>
                    <InfoBox img_box={user_jetpack} text_box="Asesoría personalizada y tu entrenamiento completo! donde aprenderás a invertir en activos digitales como criptomonedas y NFT's."/>
                    <InfoBox img_box={crypto_jetpack} text_box="Tendrás las herramientas necesarias para crear e implementar tu propia estrategia! Con Anubis Jetpack produces retornos utilizando tu nueva estrategia personalizada." side_left={!isMobile? false: true}/>
                    <InfoBox img_box={graph_jetpack} text_box="Incluye Anubis Academy, Anubis trading channel y Asesoria personalizada por 3 meses. Además, puedes inscribirte sin riesgo alguno. Ofrecemos garantía de devolución de dinero sin preguntas hasta por 5 días luego de tu compra"/>

                </div>                

                <div className='crypto__listservices-link'>
                    <h1>¿Interesado en invertir?</h1>
                    <div className='crypto__listservices-link-container'>
                        <div className='crypto__listservices-link-button'>                                                
                            <a href='#calendly'  onClick={()=>{
                                    document.getElementById("calendly").style.display='block';                                
                                }}                  
                            >Contratar</a>
                        </div>
                        <div className='crypto__listservices-link-button'>                                                
                            <a href='https://info.grupoanubis.com/advisory'>Asesoría gratis</a>
                        </div>
                        
                        <div className='crypto__listservices-link-button'>                                                
                            <a 
                                href='https://anubiscrypto.substack.com/'                 
                            >Newsletter</a>
                        </div>

                        <div className='crypto__listservices-link-button'>                                                
                            <a 
                                href='https://info.grupoanubis.com/'                 
                            >Suscríbete</a>
                        </div>
                    </div>
                </div>    
            </div>
            <div className="popup" id="popup">
                <img src={logo} alt='logo'/>
                    <h2>Ingresa tus datos</h2>
                    <p>Envianos tu nombre y correo electrónico para enviarte información importante</p>
                    <DataUserForm/>                    
            </div>
            
            <div id="calendly"  style={{minWidth:`300px`, height:`700px` , display:`none` , margin:`0rem 0rem 5rem 0rem`}}>
                <InlineWidget url="https://calendly.com/grupoanubis/30min?background_color=262626&text_color=ffffff&primary_color=e9803f"  styles={{minWidth:`300px`, height:`700px`}}/>
            </div>

        </div>
    )
}

export default ListServices