import React from 'react'
import './titlesection.css'
const Titlesection = ({title,subtitle}) => (
  <div className="anubis__section-container__feature">
    <div className="anubis__section-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className="anubis__section-container_feature-text">
      <p>{subtitle}</p>
    </div>
  </div>
);

export default Titlesection