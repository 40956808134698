import React from 'react'
import { Titlesection } from '../../../principal/components'
import ServiceCrypto from '../../components/service/ServiceCrypto'
import trend_icon from '../../../assets/trend_icon.svg'
import jetpack_icon from '../../../assets/jetpack_icon.svg'
import spider_icon from '../../../assets/spider_icon.svg'
import './ServicesSection.css'
import { InlineWidget } from 'react-calendly'

const ServicesSection = () => {
  return (
    <div className='crypto__services section__margin' id="services_crypto">
        <Titlesection title="Servicios"  /> 
        <div className="crypto__servicesg-container">
            {/* <ServiceCrypto service_img={spider_icon} navigation="select_spider" title="ANUBIS SPIDER" text="Es una estrategia de trading activo que busca generar retornos de la volatilidad de los mercados de criptomonedas. Está compuesta por 2 operadores humanos que manejan el portafolio. Estos operadores con mucha experiencia hacen trading en spot o en futuros, pueden aprovechar de movimientos alcistas o bajistas para realizar una ganancia."/> */}
            <ServiceCrypto service_img={trend_icon} navigation="select_trendfollowing" title="ANUBIS TREND FOLLOWING" text="Es una estrategia que busca seguir la tendencia del mercado para producir ganancias. Está basada en elegir, comprar y vender criptomonedas. La idea es diversificar un portafolio de criptos con diferentes tamaños de capitalización de mercado, potencial de crecimiento, y proyectos sólidos.  Hemos obtenido en el pasado para clientes retornos de 2x-5x en solo 12-18 meses."/>            
            <ServiceCrypto service_img={jetpack_icon} navigation="select_jetpack" title="ANUBIS JET PACK" text="¡Asesoría personalizada y tu entrenamiento completo! donde aprenderás a invertir en activos digitales como criptomonedas y NFT’s. ¡Incluye herramientas, cursos y entrenamientos para implementar tu propia estrategia! Con Anubis Jetpack produciras retornos utilizando tu nueva estrategia personalizada mientras te acompañan expertos. No se necesita experiencia para unirse a Anubis Jetpack."/>            
        </div>

        <div className='crypto__listservices-link' style={{margin:`2rem 0rem 0rem 0rem`}}>              
          <div className='crypto__listservices-link-container'>
              <div className='crypto__listservices-link-button'>                                                
                  <a href='#calendly'  onClick={()=>{
                          document.getElementById("calendly").style.display='block';                                
                      }}                  
                  >Contratar</a>
              </div>
              <div className='crypto__listservices-link-button'>                                                
                  <a href='https://info.grupoanubis.com/advisory'>Asesoría gratis</a>
              </div>
              
              <div className='crypto__listservices-link-button'>                                                
                  <a 
                      href='https://anubiscrypto.substack.com/'                 
                  >Newsletter</a>
              </div>
              <div className='crypto__listservices-link-button'>                                                
                  <a 
                      href='https://info.grupoanubis.com/'                 
                  >Suscríbete</a>
              </div>
          </div>
        </div> 

        <div id="calendly"  style={{minWidth:`300px`, height:`700px` , display:`none` , margin:`0rem 0rem 0rem 0rem`}}>
          <InlineWidget url="https://calendly.com/grupoanubis/30min?background_color=262626&text_color=ffffff&primary_color=e9803f"  styles={{minWidth:`300px`, height:`700px`}}/>
        </div>
    </div>
  )
}

export default ServicesSection