import React from 'react'
import ListServices from './ListServices/ListServices'
import './MainServices.css'


const MainServices = ({route}) => {
  return (
    <div id='home' className='MainServices section__padding'>
        <ListServices route={route}/>
    </div>
  )
}

export default MainServices