export const sendEmails = async (formEmail) => {
    const API = "https://qem9obampi.execute-api.us-west-2.amazonaws.com/microservice-mails";
    const url = new URL(API);    
    Object.keys(formEmail).forEach(key => url.searchParams.append(key, formEmail[key]))  
    // let resp =await fetch(url,{ method: 'POST',
    //     headers: {
    //         "Access-Control-Allow-Origin" : "*", // Required for CORS support to work
    //         'Content-Type': 'application/json, charset=utf-8'
    //     },
    //     body: formEmail,
    //     mode: 'cors'
    // });    
    
    // console.log(resp);
    // console.log(await resp.json());
    return fetch(url,{ method: 'POST',
        headers: {
            "Access-Control-Allow-Origin" : "*", // Required for CORS support to work
            'Content-Type': 'application/json, charset=utf-8'
        },
        body: formEmail,
        mode: 'cors'
    });    
    
};