import React from 'react'
import { Titlesection } from '../../components'
import monitoreo_section from '../../../assets/monitoreo_section.svg'

import './analysis.css'


const Analysis = () => {
  return (
    <div className='anubis__analysis section__margin' id="analysis_anubis">
        <Titlesection title="Watch" subtitle="¡Muy pronto! tendrás tu empresa, en tu mano" /> 
        <div className="anubis__analysisg-container">
          <div className="anubis__analysis-container">
            <img src={monitoreo_section} alt="service_img" />   
          </div>
        </div>
    </div>
  )
}

export default Analysis