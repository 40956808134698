import React from 'react'
import './InfoBox.css'

const InfoBox = ({img_box,text_box,side_left = true}) => {
    if(side_left){
        return (
            <div className='crypto__infobox'>        
                <div className='crypto__infobox-img'>
                    <img src={img_box} alt="test_video" ></img>
                </div>
                <div className='crypto__infobox-text crypto__infobox-margin-right'>
                    <p>{text_box}</p>
                </div>
            </div>
        )
    }else{
        return (
            <div className='crypto__infobox'> 
                <div className='crypto__infobox-text crypto__infobox-margin-left'>
                    <p>{text_box}</p>
                </div>       
                <div className='crypto__infobox-img'>
                    <img src={img_box} alt="test_video" ></img>
                </div>                
            </div>
        )
    }
    
}

export default InfoBox