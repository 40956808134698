import React from 'react'
import { Routes, Route } from "react-router-dom";
import NavbarCrypto from '../crypto/components/ui/NavbarCrypto';
import FooterCrypto from '../crypto/containers/footer/FooterCrypto';
import Help from '../crypto/containers/help/Help';
import MainServices from '../crypto/containers/mainservices/MainServices';
import CryptoApp from '../crypto/CryptoApp';



const CryptoRouter = ({route}) => {
  
  return (
    <div className='CryptoApp'>                
        <NavbarCrypto route= {route}/>
        <Routes>            
            <Route path="/" element={ <CryptoApp />} />            
            <Route path="/services" element={ <MainServices route={route} />} />
            <Route path="/help" element={ <Help route={route} />} />
        </Routes>
        <FooterCrypto/>
        
    </div>
  )
}

export default CryptoRouter