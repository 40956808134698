import React from 'react'

import logo from '../../../assets/logo_anubis.svg';
import './header.css'

import ReactGA from 'react-ga';
import { useNavigate } from "react-router-dom";
const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="anubis__header section__padding" id="home">
            <div className="anubis__header-content">
                <div className="anubis__header-image">
                    <img src={logo} alt="logo_header" />                
                </div>            
                <div className='anubis__header-content__title'>
                    <h1>ANUBIS</h1>
                </div>
                <div className="anubis__header-content__people">                
                    <h2>SOLUCIONES TECNOLÓGICAS</h2>
                </div>
                <div className="anubis__header-content__input">
                    <button className='analysis__button' 
                        onClick={(e) => {e.preventDefault();
                            navigate('/crypto');
                            ReactGA.event({
                                category: 'LINKS_SERVICES',
                                action: 'MOVE_TO_SECTION',
                                label: 'CRYPTO'
                            });}}
                        type="button">
                                            CRYPTO
                    </button>
                    <button className='analysis__button' 
                            onClick={(e) => {e.preventDefault();
                                window.location.href='#analysis_anubis';
                                ReactGA.event({
                                    category: 'LINKS_SERVICES',
                                    action: 'MOVE_TO_SECTION',
                                    label: 'WATCH'
                                });}}
                    type="button">WATCH</button>
                </div>                
            </div>            
        </div>
    )
}

export default Header