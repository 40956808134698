

class Calculator{    

    round2Decimals(num){
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
    
    getSpiderResults(capital,months,{cost_monthly,approximate_return,utility,cost_total,init_comision,comision,return_investment,final_capital,final_capital_percentage}){
        capital = parseInt(capital);
        months = parseInt(months);
        //Cost monthly
        if(capital<4752.69){
            cost_monthly = this.round2Decimals(capital*(7/225)+4.4444444);
        }else{
            cost_monthly = this.round2Decimals(80*Math.log(capital-1500)-494.6731);
        }

        //Init commision
        init_comision = cost_monthly;

        //approximate_return
        if(months === 6){
            approximate_return=50;
        }
        else if(months === 12){
            approximate_return=110;
        }
        else if(months === 18){
            approximate_return=175;
        }

        //utility
        utility = this.round2Decimals((approximate_return/100) * capital);

        //cost total
        cost_total= this.round2Decimals((cost_monthly * months) + init_comision);

        //comision anubis
        comision = this.round2Decimals((utility-cost_total)*0.12) ;

        //return investment
        return_investment =  this.round2Decimals(utility - cost_total - comision)

        //capital final
        final_capital = this.round2Decimals(capital + return_investment);

        //% capital final
        final_capital_percentage= this.round2Decimals((return_investment/capital)*100);

        return {
            cost_monthly,
            approximate_return,
            utility,cost_total,
            init_comision,
            comision,
            return_investment,
            final_capital,
            final_capital_percentage
        };
    }

    getTrendFollowingResults(capital,months,{cost_monthly,approximate_return,utility,cost_total,init_comision,comision,return_investment,final_capital,final_capital_percentage}){
        capital = parseInt(capital);
        months = parseInt(months);

        //init comision
        init_comision= this.round2Decimals(capital*(0.06 -((capital-1000)/400000)));

        //cost monthly
        cost_monthly = 20;
        
        //approximate_return
        if(months === 6){
            approximate_return=30;
        }
        else if(months === 12){
            approximate_return=90;
        }
        else if(months === 18){
            approximate_return=130;
        }

        //utility
        utility = this.round2Decimals((approximate_return/100) * capital);

        //cost total
        //cost_total= this.round2Decimals((cost_monthly * months) + init_comision);
        cost_total= this.round2Decimals((20 * months) );
        //comision anubis
        comision = this.round2Decimals((utility)*0.12) ;

        //return investment
        return_investment =  this.round2Decimals(utility - cost_total - comision)

        //capital final
        final_capital = this.round2Decimals(capital + return_investment);

        //% capital final
        final_capital_percentage= this.round2Decimals((return_investment/capital)*100);

        return {
            cost_monthly,
            approximate_return,
            utility,cost_total,
            init_comision,
            comision,
            return_investment,
            final_capital,
            final_capital_percentage
        };
    }
}

export default Calculator;
