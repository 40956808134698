import React from 'react'
import { Titlesection } from '../../components'
import soonanubis_image from '../../../assets/comingsoon.svg';
import './soon.css'
const Soon = () => {
  return (
    <div className="anubis__soon section__margin" id="coming_soon">
        <Titlesection title="¡Próximamente!" subtitle="Todas tus inversiones y servicios en un solo lugar" />
        <div className="anubis__soong-container">
            <div className="anubis__soon-container-image">
                <img alt="soonanubis_image" src={soonanubis_image}/>
            </div>
            <div className='anubis__soon-container-text'>
                <ul className='anubis__soon-list'>
                    <li>Plataforma segura y de fácil acceso</li>
                    <li>Servicio 24 horas del día</li>
                    <li>Personaliza tu estrategia de inversión desde tu dispositivo</li>
                    <li>Supervisa tus estrategias en tiempo real</li>
                    <li>Contrata los servicios que desees de forma sencilla y rápida</li>
                </ul>
                {/* <div className='anubis__soon-container-p'>
                    <div className='box_soon'/>
                    <p>Plataforma segura y de fácil acceso</p>                    
                </div>
                <div className='anubis__soon-container-p'>
                    <div className='box_soon'/>
                    <p>Servicio 24 horas del día</p>                    
                </div>
                <div className='anubis__soon-container-p'>
                    <div className='box_soon'/>
                    <p>Personaliza tu estrategia de inversión desde tu dispositivo</p>                    
                </div>
                <div className='anubis__soon-container-p'>
                    <div className='box_soon'/>
                    <p>Supervisa tus estrategias en tiempo real</p>                    
                </div>
                <div className='anubis__soon-container-p'>
                    <div className='box_soon'/>
                    <p>Contrata los servicios que desees de forma sencilla y rápida</p>                    
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default Soon