import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { sendEmails } from '../../../../principal/helpers/sendEmail';
import { RiArrowRightSFill } from 'react-icons/ri'

import './FormHelp.css'


const FormHelp = () => {
    //Defaults
    const defaultPlaceHolder = "";
    
    
    //Placeholders
    const placeholder_names = "John Doe";
    const placeholder_email = "email@gmail.com";
    const placeholder_message = "Necesito saber...";
    
    
    //hooks
    const [names, setNames] = useState(defaultPlaceHolder);
    const [email, setEmail] = useState(defaultPlaceHolder);
    const [message, setMessage] = useState(defaultPlaceHolder);
    const [buttonMessage, setbuttonMessage] = useState({
        desactivar: false,
        message: "ENVIAR",
        className: 'buttonSubmit'
    });
    const { handleSubmit, formState } = useForm()
    const { isSubmitting } = formState
    
    
    
    
    
    const formSubmit = (data) =>{        
        let formEmail = {
            names: names,
            email: email,
            message: message,
        };
        //Send email        
        // ReactGA.event({
        //     category: 'EMAILS',
        //     action: 'SEND_CONTACT_EMAIL',
        //     label: formEmail.names
        // });
        return sendEmails(formEmail)
        .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Something went wrong');
        })
        .then( ({error , message}) => {
            
            if(!error){
                setbuttonMessage({
                    desactivar: true,
                    message: "Enviado",
                    className: 'okSubmit'
                })
            }else{
                setbuttonMessage({
                    desactivar: false,
                    message: "Verifique los datos del formulario",
                    className: 'errorSubmit'
                })
            }            
        }).catch(e=>{
            //console.log("Error",e);
            setbuttonMessage({
                desactivar: true,
                message: "Ocurrió un error inesperado",
                className: 'errorSubmit'
            })  
        }).finally(e=>{
            //Clear
            setNames(defaultPlaceHolder);
            setEmail(defaultPlaceHolder);
            setMessage(defaultPlaceHolder); 
        });                                
    };


    return (
        <div id='help_contact' className='help__container section__padding'>
            <div className='help__text'>
                <div className='box_orange'/>
                <div className='help__text-container'>
                    <p className='help__text-container-title'>¿Buscas ayuda personalizada?</p>
                    <p className='help__text-container-sub'>Pregúntanos todo lo que necesitas saber.</p>
                </div>
            </div>
            <div className='help__form'>
                <form onSubmit={handleSubmit(formSubmit)} >   
                    <div className='help__name-mail'>
                        <div className='help__name'>
                            <p>NOMBRE</p>
                            <input
                                type="text"
                                value={names}
                                placeholder={placeholder_names}
                                onChange={e=> setNames(e.target.value)}
                                required
                            />
                        </div> 
                        <div className='help__mail'>
                            <p>E-MAIL</p>
                            <input
                                type="email"                    
                                value={email}
                                placeholder={placeholder_email}
                                onChange={ e=> setEmail(e.target.value)}
                                required
                            />
                        </div>
                    </div>      
                    <input
                        type="text"
                        value={message}                        
                        placeholder={placeholder_message}
                        onChange={ e=> setMessage(e.target.value)}
                        required
                    />
                    <button type="submit"
                            className={buttonMessage.className}
                            disabled={isSubmitting || buttonMessage.desactivar}>
                    {isSubmitting ? (
                        <div className='divLoader'><div className="loader"></div></div>
                    
                    ): ( <div className='div__button'> <span >{ buttonMessage.message } </span> <RiArrowRightSFill className='arrow_right'/>  </div>)}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default FormHelp