import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { sendEmails } from '../../helpers/sendEmail';
import ReactGA from 'react-ga';
import './contactform.css'

const ContactForm = () => {

    //Defaults
    const defaultPlaceHolder = "";
    
    
    //Placeholders
    const placeholder_names = "Nombres";
    const placeholder_email = "Correo electrónico";
    const placeholder_message = "Mensaje / Consulta";
    
    //hooks
    const [names, setNames] = useState(defaultPlaceHolder);
    const [email, setEmail] = useState(defaultPlaceHolder);
    const [message, setMessage] = useState(defaultPlaceHolder);
    const [buttonMessage, setbuttonMessage] = useState({
        desactivar: false,
        message: "Enviar",
        className: 'buttonSubmit'
    });
    const { handleSubmit, formState } = useForm()
    const { isSubmitting } = formState
    
    
    
    
    
    const formSubmit = (data) =>{        
        let formEmail = {
            names: names,
            email: email,
            message: message,
        };
        //Send email        
        ReactGA.event({
            category: 'EMAILS',
            action: 'SEND_CONTACT_EMAIL',
            label: formEmail.names
        });
        return sendEmails(formEmail)
        .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Something went wrong');
        })
        .then( ({error , message}) => {
            
            if(!error){
                setbuttonMessage({
                    desactivar: true,
                    message: "Enviado ✔",
                    className: 'okSubmit'
                })
            }else{
                setbuttonMessage({
                    desactivar: false,
                    message: "Verifique los datos del formulario",
                    className: 'errorSubmit'
                })
            }            
        }).catch(e=>{
            //console.log("Error",e);
            setbuttonMessage({
                desactivar: true,
                message: "Ocurrió un error inesperado",
                className: 'errorSubmit'
            })  
        }).finally(e=>{
            //Clear
            setNames(defaultPlaceHolder);
            setEmail(defaultPlaceHolder);
            setMessage(defaultPlaceHolder); 
        });                                
    };

    return (
        <div  className='anubis__form'>
            <form onSubmit={handleSubmit(formSubmit)} >          
                <input
                    type="text"
                    value={names}
                    placeholder={placeholder_names}
                    onChange={e=> setNames(e.target.value)}
                    required
                />
                <input
                    type="email"                    
                    value={email}
                    placeholder={placeholder_email}
                    onChange={ e=> setEmail(e.target.value)}
                    required
                />
                <input
                    type="text"
                    value={message}
                    className='textArea'
                    placeholder={placeholder_message}
                    onChange={ e=> setMessage(e.target.value)}
                    required
                />
                <button type="submit"
                        className={buttonMessage.className}
                        disabled={isSubmitting || buttonMessage.desactivar}>
                {isSubmitting ? (
                    <div className='divLoader'><div className="loader"></div></div>
                  
                ): (<span >{ buttonMessage.message }</span>)}
                </button>
            </form>
        </div>
    )
}

export default ContactForm