import React from 'react'
import './HeaderHelp.css'

const HeaderHelp = () => {
  return (
    <div className="help__header section__padding" id="home">
        <div className="help__header-content section__margin">                  
            <div className='help__header-content__title'>
                <div className='help__header-content__title_highlight'>
                    <h1>AYUDA</h1>
                </div>
                <div className='help__header-content__title_sub' >
                    <h1>¡Queremos ayudarte! Revisa las preguntas frecuentes o contáctanos para una ayuda personalizada</h1>
                </div>
            </div>
            <div className="help__header-content__input">            
                <button className='dash__button' 
                        onClick={(e) => {
                            e.preventDefault();     
                            window.location.href='#help_contact';                       
                            // ReactGA.event({
                            //     category: 'LINKS_SERVICES',
                            //     action: 'MOVE_TO_SECTION',
                            //     label: 'MONITOREO'
                            // });
                        }}
                type="button"> CONTÁCTANOS</button>
            </div>                
        </div>            
    </div>
  )
}

export default HeaderHelp