import React, { useState, useEffect } from 'react'
import './navbar.css'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import logo from '../../../assets/logo_anubis.svg'

const Navbar = () => {

    const [toggleMenu, setToggleMenu] = useState(false);
    //const [leaveMenu, setLeaveMenu] = useState(false);
    
    useEffect(()=>{       
        if(toggleMenu){
            setTimeout(()=>{                
                setToggleMenu(false);
                //setLeaveMenu(false);
            },5000);
        }
    },[toggleMenu]);

    useEffect(() => {        
        window.onscroll = function() {narvbarScroll()};

        var navbar = document.getElementById("navbar");
        var wanubis = document.getElementById("wanubis");
        var home = document.getElementById("home");

        var sticky = wanubis.offsetTop;
        var hide_show = home.offsetTop;

        let before= 0;
        let down = true;
        const narvbarScroll = () =>{
            let now = window.pageYOffset;         
            down = now>before? true: false;                                    
            if (now  < sticky && now  > hide_show ) {
                if(down){
                    navbar.classList.add("hide_navbar");
                }else{
                    navbar.classList.remove("hide_navbar");
                }
            } 
            else if(window.pageYOffset > sticky){
                navbar.classList.add("sticky_navbar");
            }
            else {                
                navbar.classList.remove("sticky_navbar");
            }
            before=now;
        }
    });

    return (
        <div className='anubis__navbar' id="navbar">
            <div className='anubis__navbar-links'>
                <div className="anubis__navbar-links_logo">
                    <img src={logo} alt="logo"></img>
                    <div>
                        <a href='#home'>ANUBIS</a>
                    </div>
                    
                </div>
                <div className='anubis__navbar-links_container'>
                    <p><a href='#home'>Inicio</a></p>
                    <p><a href='#services_anubis'>Servicios</a></p>
                    <p><a href='#coming_soon'>¡Próximamente!</a></p>
                    <p><a href='#anubis_contact'>Contacto</a></p>
                    
                </div>
            </div>

            {/* <div className="anubis__navbar-sign">                
                <button className='buttonNavbar' type="button">Ingresar</button>
            </div> */}
 
            <div className="anubis__navbar-menu">
                {(toggleMenu)
                    ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
                {(toggleMenu ) && (
                <div className="anubis__navbar-menu_container" 
                    // onMouseDown={(e)=>{                        
                    //     setLeaveMenu(true);
                    // }}
                >
                    <div className="anubis__navbar-menu_container-links">
                        <p><a href='#home'>Inicio</a></p>
                        <p><a href='#services_anubis'>Servicios</a></p> 
                        <p><a href='#coming_soon'>¡Próximamente!</a></p>                   
                        <p><a href='#container_form'>Contacto</a></p>
                    </div>
                    {/* <div className="anubis__navbar-menu_container-links-sign">
                        <button type="button">Ingresar</button>
                    </div> */}
                </div>
                )}
            </div>
        </div>
    )
}

export default Navbar