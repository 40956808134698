export const saveUserData = async (name, email) => {

    const API = 'https://yrdkhyzboi.execute-api.us-west-2.amazonaws.com/test_anubis/users-data';
    const url = new URL(API);
    const body = {
        name,email
    };
    console.log(body)
    return fetch(url,{ method: 'POST',
    mode: 'cors',
    body: JSON.stringify(body)
});

}

export default saveUserData;