import React from 'react'
import "./HeaderCrypto.css"
const HeaderCrypto = () => {
  return (
    <div className="crypto__header section__padding" id="home">
        <div className="crypto__header-content section__margin">                  
            <div className='crypto__header-content__title'>
                {/* <div className='crypto__header-content__title_sub' >
                    <h1>Especializados en <br/>criptomonedas y tecnología</h1>
                </div> */}
                <div className='crypto__header-content__title_highlight'>
                    <h1>CRECEMOS TU PATRIMONIO</h1>
                </div>
            </div>
            <div className="crypto__header-content__input">                
                <button className='dash__button' 
                        onClick={(e) => {e.preventDefault();
                            window.location.href="http://crypto.grupoanubis.com"                            
                            // ReactGA.event({
                            //     category: 'LINKS_SERVICES',
                            //     action: 'MOVE_TO_SECTION',
                            //     label: 'MONITOREO'
                            // });
                        }}
                type="button">EMPEZAR</button>
            </div>                
        </div>            
    </div>
  )
}

export default HeaderCrypto