import React, { useEffect, useState } from 'react'
import Calculator from '../../classes/Calculator';
import CalculatorValue from '../calculatorvalue/CalculatorValue'

const CalculatorUI = ({id,name_strategy}) => {

    
    const minMaxValue  = {
        min:500,
        max:500000
    };
    
    const calc = new Calculator();

    const [calculatorValues,setCalculatorValues] = useState({        
        cost_monthly: 0,
        approximate_return:0,
        utility:0,
        cost_total:0,
        init_comision:0,
        comision:0,
        return_investment:0,
        final_capital:0,
        final_capital_percentage:0,            
    });

    const [investment, setInvestment] = useState(5000);
    const [month, setMonth] = useState(12);
    
    

    const calcValues = () => {        
        if(id === "calculator_spider"){            
            const result = calc.getSpiderResults(investment,month,calculatorValues);            
            setCalculatorValues(result);
        }else if(id === "calculator_trend_following"){
            const result = calc.getTrendFollowingResults(investment,month,calculatorValues);            
            setCalculatorValues(result);
        }
    };

    
    useEffect( () => {                
        if(!(investment < minMaxValue.min || investment> minMaxValue.max)){
            calcValues();
        }
    }, [investment,month]);

    const changeInvestment = async (e) => { 
        const sleep = m => new Promise(r => setTimeout(r, m));
        const time_sleep = 5000;

        setInvestment(e.target.value);                

        if(e.target.value < minMaxValue.min){
            await sleep(time_sleep);                        
            setInvestment(e.target.value < minMaxValue.min ? minMaxValue.min: e.target.value);
        }else if(e.target.value > minMaxValue.max){
            await sleep(time_sleep);    
            setInvestment(e.target.value > minMaxValue.max ? minMaxValue.max : e.target.value);                   
        }                                           
    };

    const changeMonth = (e) => {                   
        setMonth(e.target.value);              
    };


    

    return (
        <div id={id} className='crypto__listservices-calculator'>
            <div className='crypto__listservices-calculator-title'>
                <h3>Proyección de estrategia {name_strategy}</h3>
            </div>

            <div className='crypto__listservices-calculator-container'>
                <div className='crypto__listservices-calculator-container-left'>
                    <div className='crypto__listservices-calculator-form'>

                        <div className='crypto__listservices-calculator-form-input'>
                            <p>Capital</p>                
                            <div>
                                <span>$</span>
                                <input id={`${id}-input`} type='number' min={minMaxValue.min} max={minMaxValue.max} 
                                    value={investment} 
                                    onClick={()=>{setInvestment(0)}}
                                    onChange={changeInvestment}/>
                            </div>                                    
                        </div>

                        <div className='crypto__listservices-calculator-form-input'>
                            <p>Plazo de la inversión <sup>1</sup> </p>                                    
                            <span className='crypto__months'>{ month } meses</span>
                            <input id='range' type='range'
                                    min= "6" max="18" value={month} step="6" onChange={changeMonth}/>                                                                       
                        </div>
                    </div>
                    
                    <CalculatorValue title='Comisión inicial' 
                        value={calculatorValues.init_comision}
                        isPercentage={false}                                
                        //enumerator={1}
                        />
                    <CalculatorValue title='Costo operativo mensual' 
                        value={calculatorValues.cost_monthly}
                        isPercentage={false}
                        // returnPercentage={57.09}
                        //enumerator={1}
                        />

                </div>
                <div className='crypto__listservices-calculator-container-right'>
                    <CalculatorValue title='Retorno aproximado' 
                            value={calculatorValues.approximate_return}
                            isPercentage={true}
                            // returnPercentage={57.09}                                    
                            />
                    <CalculatorValue title='Utilidad' 
                            value={calculatorValues.utility}
                            isPercentage={false}
                            //returnPercentage={57.09}                                    
                            />
                    <CalculatorValue title='Costo operativo total' 
                            value={calculatorValues.cost_total}
                            isPercentage={false}
                            // returnPercentage={57.09}                                    
                            />
                    <CalculatorValue title='Comisión' 
                            value={calculatorValues.comision}
                            isPercentage={false}
                            // returnPercentage={57.09}
                            enumerator={2}
                            />
                    <CalculatorValue title='Retorno sobre inversión' 
                            value={calculatorValues.return_investment}
                            isPercentage={false}
                            // returnPercentage={57.09}
                            enumerator={3}
                            />
                    <CalculatorValue title='Capital final' 
                            value={calculatorValues.final_capital}
                            isPercentage={false}
                            returnPercentage={calculatorValues.final_capital_percentage}
                            isFinalCapital={true}
                            //enumerator={3}
                            />
                </div>
            </div>
        </div>
  )
}

export default CalculatorUI