import React, { useEffect } from 'react'
import FormHelp from './form/FormHelp'
import HeaderHelp from './header/HeaderHelp'

import './Help.css'
import Questions from './questions/Questions'
const Help = ({route}) => {

  useEffect(()=>{
    if(window.location.hash.includes('help_contact')){
      window.location.hash = 'help_contact';
      document.getElementById('help_contact').focus();
    }
  },[]);
  return (
    <div className='CryptoApp'>
        <HeaderHelp/>
        <Questions/>
        <FormHelp/>
    </div>
  )
}

export default Help