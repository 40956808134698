import React from 'react'
import { NavLink } from 'react-router-dom';
import { phone, mail, whatsapp, anubisLogo, googleplus, instagram, linkedin } from './import'

const FooterCrypto = () => {
  return (
    <div className="anubis__footer section__padding">
      <div className='anubis_footer-contact-info'>
        <div className='anubis_footer-contact-info_title'>
          <p>CONTÁCTANOS</p>
        </div>
        <div className='anubis_footer-contact-info_phone'>
        <div className='info_button phone_button'>
              <a href='tel:+593999196711'>
                <img alt="phone_anubis" src={phone}  />
              </a>        
            </div>
            <div className='info_button mail_button'>
              <a href='mailto:servicioalcliente@grupoanubis.com'>
                <img alt="mail_anubis" src={mail}  />
              </a>        
            </div>
            <div className='info_button whatsapp_button'>
              <a href='https://wa.me/593993770317' >
                <img alt="ws_anubis" src={whatsapp}  />
              </a>        
            </div>
            <div className='info_text'>
              <p className='subtitle_phone'>Lun a Vie - 09h00 a 16h00</p>
              <p className='phone_number'>+593-993770317</p>
            </div>
        </div>
      </div>
      <div className="anubis__footer-links">
        <div className="anubis__footer-links_logo">
          <div className="anubis__footer-logo_img">
              <img src={anubisLogo} alt="logo"></img>
              <div>
                  <a href='#home'>ANUBIS</a>
              </div>                    
          </div>
          <div>
            <p>Copyright © 2021 Anubis Crypto. <br /> All Rights Reserved</p>
          </div>
          <div className='anubis__footer-social-network'>
            <div>
              <a className='google_plus' href='mailto:servicioalcliente@grupoanubis.com'>
                <img src={googleplus} alt="google_plus"></img>
              </a>
            </div>
            <div>
              <a className='instagram' href='https://www.instagram.com/anubiscrypto'>
                <img src={instagram} alt="instagram"></img>
              </a>
            </div>
            <div>
              <a className='linkedin' href='https://www.linkedin.com/company/anubis-solutions/'>
                <img src={linkedin} alt="linkedin"></img>
              </a>
            </div>
            
          </div>
        </div>
        <div className="anubis__footer-links_div">
          <div>
            <a href='#home'>
              <p>INICIO</p>
            </a>
            {/* <a href='#services_anubis'>
              <p>SERVICIOS</p>
            </a>           */}
          </div>       
          <div>             
            <NavLink
                    to="/crypto/services" 
            ><p>SERVICIOS</p></NavLink>
            {/* <a href='#crypto_anubis'>
              <p>CRYPTO</p>
            </a>    */}
          </div>    
          <div>                          
            <NavLink 
                    to="/crypto/help#help_contact"                    
            ><p>CONTACTO</p></NavLink>
            {/* <a href='#anubis_contact'>
              <p>CONTACTO</p>
            </a>    */}
          </div>             
        </div>
      
    </div>

    
  </div>
  )
}

export default FooterCrypto